<template>
    <v-dialog v-model="yearPicker" persistent width="30%">
        <v-card>
            <v-card-title>Yearly Analytics</v-card-title>
            <v-card-text>
                <v-text-field v-model.number="year"
                    label="Enter Year to get analytics"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="fetchYearData()">View</v-btn>
                <V-btn @click="closeDialog()" color="error">Close</V-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'yearPicker',
    data() {
        return {
            year: null
        }
    },
    computed: {
        ...mapGetters(['yearPicker'])
    },
    methods: {
        closeDialog() {
            this.$store.commit('setYearPicker', false)
            this.$store.commit('setcloseWithoutSelection', 1)
            // this.$store.dispatch('getKioskAnalytics', {
            //     type: 'Today',
            //     start: null,
            //     end: null
            // })
        },
        fetchYearData() {
            console.log('Selected Year: ', this.year)
            this.$store.commit("setEnteredYear", this.year)
            this.$store.dispatch('getKioskAnalytics', {
                type: 'Select Year',
                start: null,
                end: null
            })
            this.$store.commit('setYearPicker', false)
            this.year = null
        }
    }
}
</script>